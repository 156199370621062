import getDefaultState from './stateInitializer'

export default {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  SET_PRICES(state, prices) {
    state.prices = prices
  },
}
