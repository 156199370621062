export default {
  async fetchTilesData({ commit, dispatch }, date) {
    try {
      const data = (await this._vm.$http.get(`dashboard/tiles-data/${date ?? ''}`)).data
      commit('SET_TILES_DATA', data)
    } catch (e) {
      dispatch('showToast', {}, { root: true })
    }
  },
}
