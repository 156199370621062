import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { HTTP } from './http-common';
import VueScrollTo from 'vue-scrollto'

Vue.prototype.$http = HTTP
Vue.config.productionTip = false
Vue.use(VueScrollTo)

Vue.filter('formatToOnlyFirstWordCapital', function (value) {
  if (!value) return ''
  value = value.toString().toLowerCase()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
