export default {
  async fetchUser({ commit, state, dispatch }) {
    if (state.token) {
      try {
        const user = (await this._vm.$http.get('users/me')).data
        commit('SET_USER', user)
      } catch (e) {
        dispatch('showToast', {}, { root: true })
      }
    }
    return
  },
  async signIn({ commit }, payload) {
    try {
      console.log('sing in')
      const data = (await this._vm.$http.post('auth/signin', payload.login)).data
      console.log('sing izzn')
      if (payload.rememberMe) {
        localStorage.setItem('token', data.token)
      } else {
        localStorage.setItem('token', data.token)
      }
      commit('SET_USER', data.user)
      commit('SET_TOKEN', data.token)
    } catch (e) {
      return false
    }
    return true
  },
  logout({ commit }) {
    localStorage.removeItem("token");
    localStorage.removeItem("token");
    commit('SET_TOKEN', undefined)
    commit('login/RESET_STATE', null, { root: true })
  },
}
