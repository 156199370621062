<template>
  <v-app v-if="isDataLoaded">
    <desktop-nav-bar v-if="token" />
    <v-main>
      <top-section v-if="token" />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import DesktopNavBar from "@/views/NavBar/DesktopNavBar.vue";
import TopSection from "./views/TopSection/TopSection.vue";
import { mapState } from "vuex";

export default {
  name: "App",
  components: {
    DesktopNavBar,
    TopSection,
  },
  data: () => ({ isMobile: false, isDataLoaded: false }),
  computed: {
    ...mapState({
      token: (state) => state.login.token,
    }),
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  async mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
    await this.$store.dispatch("login/fetchUser");
    this.isDataLoaded = true;
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth <= 820;
    },
  },
};
</script>

<style lang="less">
@import "./styles/app.less";
</style>
