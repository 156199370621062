<template>
  <v-row justify="end">
    <v-col>
      <v-row no-gutters align="center" justify="end">
        <h5 class="ap-text-color">
          {{ `${user.firstName} ${user.lastName}` }}
        </h5>
        <v-btn to="/logout" icon color="#37A3C6">
          <v-icon @click="logout">mdi-logout</v-icon>
        </v-btn>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "TopSection",
  computed: {
    ...mapState({
      user: (state) => state.login.user,
    }),
  },
  methods: {
    logout() {},
  },
};
</script>