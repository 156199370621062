import { render, staticRenderFns } from "./DesktopNavBar.vue?vue&type=template&id=a073a86c&scoped=true&"
import script from "./DesktopNavBar.vue?vue&type=script&lang=js&"
export * from "./DesktopNavBar.vue?vue&type=script&lang=js&"
import style0 from "./DesktopNavBar.vue?vue&type=style&index=0&id=a073a86c&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a073a86c",
  null
  
)

export default component.exports