export default {
  async fetchProfessionals({ rootState, commit, dispatch }) {
    try {
      const data = (await this._vm.$http.get(`users?join=enterprise&join=subscription&join=subscription.items`)).data
      commit('SET_PROFESSIONALS', data.filter(w => w.id !== rootState.login.user.id))
    } catch (e) {
      dispatch('showToast', {}, { root: true })
    }
  },
  async fetchOne({ dispatch }, id) {
    try {
      return (await this._vm.$http.get(`users/${id}/detailed-card`)).data
    } catch (e) {
      dispatch('showToast', {}, { root: true })
    }
  },
  async fetchProfessionalsToPay({ dispatch }, date) {
    try {
      return (await this._vm.$http.get(`users/professionals-to-pay/${date ?? ''}`)).data
    } catch (e) {
      dispatch('showToast', {}, { root: true })
    }
  },
}
