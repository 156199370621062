import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Root',
    redirect: '/dashboard'
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "professionals" */ '../pages/Dashboard.vue')
  },
  {
    path: '/professionals',
    name: 'Professionals',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "professionals" */ '../pages/Professionals.vue')
  },
  {
    path: '/professionals/:id',
    name: 'ProfessionalProfile',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "professional-profile" */ '../pages/ProfessionalProfile.vue')
  },
  {
    path: '/professionals-to-pay',
    name: 'ProfessionalsToPay',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "professionals-to-pay" */ '../pages/ProfessionalsToPay.vue')
  },
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../pages/Login.vue')
  },
  {
    path: '/logout',
    name: 'Logout',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "logout" */ '../pages/Logout.vue')
  },
  {
    path: '*',
    name: 'WorkInProgress',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "work-in-progress" */ '../pages/WorkInProgress.vue')
  }
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  // Protected routes
  if (['/dashboard'].includes(to.fullPath) && !store.getters['login/loggedIn']) {
    return next('/login');
  }
  // If logged redirect to profile
  if (to.fullPath === '/login' && store.getters['login/loggedIn']) {
    return next('/dashboard');
  }
  next()
})

export default router
