import getDefaultState from './stateInitializer'

export default {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  SET_PROFESSIONALS(state, data) {
    state.professionals = data
  },
}
