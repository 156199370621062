<template>
  <v-navigation-drawer permanent app>
    <v-list-item class="DesktopNavBar--LogoListItem">
      <v-list-item-content>
        <v-row justify="center">
          <div class="py-4">
            <v-img
              alt="Logo Action Prevention"
              :src="getImage('logo-white.png')"
              width="100"
            />
          </div>
        </v-row>
      </v-list-item-content>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense nav>
      <v-list-item link to="/dashboard">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Tableau de bord</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link to="/professionals">
        <v-list-item-icon>
          <v-icon>mdi-account-group-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Professionels</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item link to="/professionals-to-pay">
        <v-list-item-icon>
          $
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Professionels à payer</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "DesktopNavBar",
  computed: {
    ...mapGetters(["getImage"]),
  },
};
</script>

<style lang="less" scoped>
@import "../../styles/NavBar/desktop-nav-bar.less";
</style>

