export default {
  async fetchPrices({ commit, dispatch }) {
    try {
      const prices = (await this._vm.$http.get('subscriptions/prices')).data.data
      commit('SET_PRICES', prices)
    } catch (e) {
      dispatch('showToast', {}, { root: true })
    }
  },
}
