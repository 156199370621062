const getDefaultState = () => {
	return {
		tilesData: {
			nbDiagnostic: 0,
			nbBese: 0,
			nbProcedure: 0,
			nbSubscription: 0,
			nbPartnership: 0,
			procedureFileDownloadedPercentage: 0,
			buyingBesePercentage: 0,
			buyingProcedurePercentage: 0,
			partnershipPercentage: 0,
		},
	}
}

export default getDefaultState
