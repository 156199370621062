import getDefaultState from './stateInitializer'

export default {
  RESET_STATE(state) {
    Object.assign(state, getDefaultState())
  },
  SET_TILES_DATA(state, data) {
    state.tilesData = data
  },
}
